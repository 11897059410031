import { IFontFace, SupportedFontTypes, SupportedFonts } from '../types';

export const fontFace: IFontFace[] = [
  {
    name: 'Silverspoon',
    sources: [{ source: SupportedFonts.SilverspoonRegular, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
  {
    name: 'Silverspoon Bold',
    sources: [{ source: SupportedFonts.SilverspoonBold, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
  {
    name: 'Silverspoon Light',
    sources: [{ source: SupportedFonts.SilverspoonLight, type: SupportedFontTypes.woff2 }],
    style: 'normal',
    weight: 'normal',
    display: 'swap',
  },
  {
    name: 'Monserrat',
    sources: [
      { source: SupportedFonts.Monserrat, type: SupportedFontTypes.ttfVariable },
      { source: SupportedFonts.Monserrat, type: SupportedFontTypes.ttfVariableLegacy },
    ],
    style: 'oblique 0deg 10deg',
    weight: '100 900',
    display: 'swap',
  },
];
