import { createGlobalStyle } from 'styled-components';

import { primitive } from '../../designTokens/primitives';
import { rootVars } from '../base';
import { mediaQuery } from '../mediaQueries';

export const GlobalStyles = createGlobalStyle`
  ${rootVars}
  html {
    --font-hero: ${primitive.fhs.$hero};
    --font-headerOne: ${primitive.fhs.$headerOne};
    --font-headerTwo: ${primitive.fhs.$headerTwo};
    --font-headerThree: ${primitive.fhs.$headerThree};
    --font-headerFour: ${primitive.fhs.$headerFour};
    --font-headerFive: ${primitive.fhs.$headerFive};
    --font-copyOne: ${primitive.fhs.$copyOne};
    --font-copyTwo: ${primitive.fhs.$copyTwo};

    @media ${mediaQuery.desktopSmall} {
      --font-headerOne: ${primitive.fhs.$headerOneDesktop};
      --font-headerTwo: ${primitive.fhs.$headerTwoDesktop};
      --font-headerThree: ${primitive.fhs.$headerThreeDesktop};
      --font-headerFour: ${primitive.fhs.$headerFourDesktop};
      --font-copyOne: ${primitive.fhs.$copyOneDesktop};
      --font-copyTwo: ${primitive.fhs.$copyTwoDesktop};
    }

    @media ${mediaQuery.desktopLarge} {
      --font-hero: ${primitive.fhs.$heroDesktop};
    }
  }
`;
