import { primitive } from '../../designTokens/primitives';
import { IColor } from '../types/color';

export const color: IColor = {
  primary: primitive.fhs.$fhsRed,
  secondary: primitive.fhs.$fhsRedDarken10,
  tertiary: primitive.fhs.$fhsNavy,
  black: primitive.$black,
  brightWhite: primitive.$white,
  white: primitive.fhs.$houseLight,
  buttonsDisabledBackground: primitive.$blackOpacity10,
  buttonsDisabledLabel: primitive.$disabledText,
  buttonsPrimaryHover: primitive.fhs.$fhsRed,
  buttonsSecondaryActive: primitive.fhs.$fhsRedDarken10,
  buttonsSecondaryHover: primitive.fhs.$fhsRedOpacity10,
  buttonsFocused: primitive.fhs.$fhsRedDarken10,
  buttonsPrimaryActive: primitive.fhs.$fhsRedDarken10,
  inputsFocused: primitive.fhs.$fhsNavy,
  statusValidated: '',
  statusError: '',
  statusPrice: '',
  statusSuccess: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
};
